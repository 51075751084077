import DraftOption, {ImageUrls} from "./DraftOption";


class Aoe2Map extends DraftOption {

    public static readonly ALL = [
        new Aoe2Map("Acclivity"),
        new Aoe2Map("Acropolakes"),
        new Aoe2Map("Acropolis"),
        new Aoe2Map("African Clearing"),
        new Aoe2Map("African Reed Beds"),
        new Aoe2Map("African River"),
        new Aoe2Map("African Springs"),
        new Aoe2Map("African Waters"),
        new Aoe2Map("Aftermath"),
        new Aoe2Map("Aguadona"),
        new Aoe2Map("Aguaje Swamp"),
        new Aoe2Map("Alpine"),
        new Aoe2Map("Alpine Lakes"),
        new Aoe2Map("Amazon Tunnel"),
        new Aoe2Map("Anatolia"),
        new Aoe2Map("Ancient Lake"),
        new Aoe2Map("Andes"),
        new Aoe2Map("Antarctica"),
        new Aoe2Map("Aquapolis"),
        new Aoe2Map("Arabia"),
        new Aoe2Map("Archipelago"),
        new Aoe2Map("Arctic Circle"),
        new Aoe2Map("Arena"),
        new Aoe2Map("Arena-ception"),
        new Aoe2Map("Artificial"),
        new Aoe2Map("Arzachel"),
        new Aoe2Map("Atacama"),
        new Aoe2Map("Atlantean Relics"),
        new Aoe2Map("Atlantic"),
        new Aoe2Map("Atoll"),
        new Aoe2Map("Aztlan"),
        new Aoe2Map("Badlands"),
        new Aoe2Map("Ball-O-rena"),
        new Aoe2Map("Baltic"),
        new Aoe2Map("Barrier Reef"),
        new Aoe2Map("Bay"),
        new Aoe2Map("Beachfight"),
        new Aoe2Map("Bedouins"),
        new Aoe2Map("Benteng"),
        new Aoe2Map("Bermuda"),
        new Aoe2Map("Big Freeze"),
        new Aoe2Map("Bigfoot"),
        new Aoe2Map("Black Forest"),
        new Aoe2Map("Black Swamp"),
        new Aoe2Map("Blockades"),
        new Aoe2Map("Boards of Canada"),
        new Aoe2Map("Bog"),
        new Aoe2Map("Bog Islands"),
        new Aoe2Map("Bogland"),
        new Aoe2Map("Border Dispute"),
        new Aoe2Map("Border Wars"),
        new Aoe2Map("Boulder Forest"),
        new Aoe2Map("Boundary Brawl"),
        new Aoe2Map("Brahmaputra"),
        new Aoe2Map("Brood War"),
        new Aoe2Map("Brooks"),
        new Aoe2Map("Budapest"),
        new Aoe2Map("Burrito Brawl"),
        new Aoe2Map("Bypass"),
        new Aoe2Map("Caatinga"),
        new Aoe2Map("Canberra"),
        new Aoe2Map("Canyon"),
        new Aoe2Map("Canyon Lake"),
        new Aoe2Map("Canyons"),
        new Aoe2Map("Cape of Storms"),
        new Aoe2Map("Caribbean"),
        new Aoe2Map("Cataclysm"),
        new Aoe2Map("Catch Em All"),
        new Aoe2Map("Cattails"),
        new Aoe2Map("Cattleland"),
        new Aoe2Map("Cenotes"),
        new Aoe2Map("Chaos Pit"),
        new Aoe2Map("Chile con Carne"),
        new Aoe2Map("Chive Topped Hummus"),
        new Aoe2Map("Chutes"),
        new Aoe2Map("Circus"),
        new Aoe2Map("Civil War"),
        new Aoe2Map("Clearing"),
        new Aoe2Map("Coast to Mountain"),
        new Aoe2Map("Coastal"),
        new Aoe2Map("Coastal Forest"),
        new Aoe2Map("Colosseum"),
        new Aoe2Map("Comet"),
        new Aoe2Map("Compass"),
        new Aoe2Map("Continent"),
        new Aoe2Map("Continental"),
        new Aoe2Map("Coral Islands"),
        new Aoe2Map("Costa do Ouro"),
        new Aoe2Map("Crater"),
        new Aoe2Map("Crater Lake"),
        new Aoe2Map("Crescent"),
        new Aoe2Map("Criss Cross"),
        new Aoe2Map("Crop Circles"),
        new Aoe2Map("Crossfire Cooking"),
        new Aoe2Map("Cup"),
        new Aoe2Map("Danakil"),
        new Aoe2Map("Decentring"),
        new Aoe2Map("Delta"),
        new Aoe2Map("Desert"),
        new Aoe2Map("Desert Bara"),
        new Aoe2Map("Desert Paradise"),
        new Aoe2Map("Desert Slope"),
        new Aoe2Map("Desert Void"),
        new Aoe2Map("Desertfox"),
        new Aoe2Map("Disc"),
        new Aoe2Map("Donut"),
        new Aoe2Map("Donut Delight"),
        new Aoe2Map("Double African Waters"),
        new Aoe2Map("Double Antarctica"),
        new Aoe2Map("Double Arabia"),
        new Aoe2Map("Double Aztlan"),
        new Aoe2Map("Double Bazaar War"),
        new Aoe2Map("Double Delta"),
        new Aoe2Map("Double Ellie's Belly"),
        new Aoe2Map("Double Farmland"),
        new Aoe2Map("Double Flooded Cross"),
        new Aoe2Map("Double Floodplains"),
        new Aoe2Map("Double Fort"),
        new Aoe2Map("Double Gobi"),
        new Aoe2Map("Double Homeland"),
        new Aoe2Map("Double Huangshan"),
        new Aoe2Map("Double Jalapão"),
        new Aoe2Map("Double Krakatau"),
        new Aoe2Map("Double Leaf Lakes"),
        new Aoe2Map("Double Lochs"),
        new Aoe2Map("Double Meadow"),
        new Aoe2Map("Double Migration"),
        new Aoe2Map("Double Northern Isles"),
        new Aoe2Map("Double Okavango"),
        new Aoe2Map("Double Ounianga"),
        new Aoe2Map("Double Outback"),
        new Aoe2Map("Double Pond Inspection"),
        new Aoe2Map("Double Riverbed"),
        new Aoe2Map("Double Ruins Plunder"),
        new Aoe2Map("Double Salt Lake"),
        new Aoe2Map("Double Savanna"),
        new Aoe2Map("Double Torysh"),
        new Aoe2Map("Double Wings"),
        new Aoe2Map("Double Xerotes"),
        new Aoe2Map("Drought"),
        new Aoe2Map("Dry Lake"),
        new Aoe2Map("Dry River"),
        new Aoe2Map("Duel Enchiladas"),
        new Aoe2Map("Dune du Pilat"),
        new Aoe2Map("Duopoly"),
        new Aoe2Map("Dzong"),
        new Aoe2Map("Eastern Coastline"),
        new Aoe2Map("El Dorado"),
        new Aoe2Map("Enclosed"),
        new Aoe2Map("Enemy Archipelago"),
        new Aoe2Map("Enemy Islands"),
        new Aoe2Map("Eruption"),
        new Aoe2Map("Evacuation"),
        new Aoe2Map("Excavation"),
        new Aoe2Map("Eye"),
        new Aoe2Map("Fish Beach"),
        new Aoe2Map("Fish n Fish"),
        new Aoe2Map("Fishcake Fiesta"),
        new Aoe2Map("Fissure"),
        new Aoe2Map("Flood"),
        new Aoe2Map("Flooded Cross"),
        new Aoe2Map("Forest Pond"),
        new Aoe2Map("Fortified Clearing"),
        new Aoe2Map("Fortress"),
        new Aoe2Map("Four Lakes"),
        new Aoe2Map("Four Quarters"),
        new Aoe2Map("Fractal"),
        new Aoe2Map("Frazil"),
        new Aoe2Map("Frigid Lake"),
        new Aoe2Map("Frisia"),
        new Aoe2Map("Front Line"),
        new Aoe2Map("Frozen Forest"),
        new Aoe2Map("Frozen River"),
        new Aoe2Map("Geothermal Valley"),
        new Aoe2Map("Ghost Lake"),
        new Aoe2Map("Ghostly Goulash"),
        new Aoe2Map("Ghostswamp"),
        new Aoe2Map("Glade"),
        new Aoe2Map("Gobi"),
        new Aoe2Map("Gold Rush"),
        new Aoe2Map("Golden Canyon"),
        new Aoe2Map("Golden Crater"),
        new Aoe2Map("Golden Cross"),
        new Aoe2Map("Golden Fort"),
        new Aoe2Map("Golden Ghost"),
        new Aoe2Map("Golden Hill"),
        new Aoe2Map("Golden Lakes"),
        new Aoe2Map("Golden Pit"),
        new Aoe2Map("Golden Swamp"),
        new Aoe2Map("Gorge"),
        new Aoe2Map("Grand Bara"),
        new Aoe2Map("Graupel"),
        new Aoe2Map("Graveyards"),
        new Aoe2Map("Great African Lake"),
        new Aoe2Map("Great Lake"),
        new Aoe2Map("Green Pit"),
        new Aoe2Map("Greenland"),
        new Aoe2Map("Haboob"),
        new Aoe2Map("Hamburger"),
        new Aoe2Map("Heartland"),
        new Aoe2Map("Hidden Forts"),
        new Aoe2Map("Hidden Ponds"),
        new Aoe2Map("Hidden Treasures"),
        new Aoe2Map("Hideout"),
        new Aoe2Map("High Seas"),
        new Aoe2Map("High Tides"),
        new Aoe2Map("Higher Ground"),
        new Aoe2Map("Highland"),
        new Aoe2Map("Hill Fort"),
        new Aoe2Map("Hills"),
        new Aoe2Map("Hippopotamus"),
        new Aoe2Map("Holy City"),
        new Aoe2Map("Homeland"),
        new Aoe2Map("Hoodoo"),
        new Aoe2Map("Houseboat"),
        new Aoe2Map("Huacachina"),
        new Aoe2Map("Huangshan"),
        new Aoe2Map("Hunger Games"),
        new Aoe2Map("Hydroplains"),
        new Aoe2Map("HyperRandom"),
        new Aoe2Map("Inundation"),
        new Aoe2Map("Island Hopping"),
        new Aoe2Map("Islands"),
        new Aoe2Map("JDGrossrox"),
        new Aoe2Map("Jacuzzi"),
        new Aoe2Map("Jalapão"),
        new Aoe2Map("Jungle Rumble"),
        new Aoe2Map("Jungle Swamp"),
        new Aoe2Map("Kalahari"),
        new Aoe2Map("Karoo"),
        new Aoe2Map("Karst"),
        new Aoe2Map("Kawasan"),
        new Aoe2Map("Kelimutu Lakes"),
        new Aoe2Map("Khamsin"),
        new Aoe2Map("Kilimanjaro"),
        new Aoe2Map("Koala"),
        new Aoe2Map("Lagoon"),
        new Aoe2Map("Land Madness"),
        new Aoe2Map("Land Nomad"),
        new Aoe2Map("Land of Lakes"),
        new Aoe2Map("Le Grand Fossé"),
        new Aoe2Map("Lençóis Maranhenses"),
        new Aoe2Map("Llanganati"),
        new Aoe2Map("Loch Ness"),
        new Aoe2Map("Lombardia"),
        new Aoe2Map("Low Tide"),
        new Aoe2Map("Lowland"),
        new Aoe2Map("Maghreb"),
        new Aoe2Map("Mangrove Flood"),
        new Aoe2Map("Marketplace"),
        new Aoe2Map("Marooned"),
        new Aoe2Map("Marsh Madness"),
        new Aoe2Map("Marshland"),
        new Aoe2Map("Meadow"),
        new Aoe2Map("Meatballs"),
        new Aoe2Map("Mediterranean"),
        new Aoe2Map("MegaRandom"),
        new Aoe2Map("Meltdown"),
        new Aoe2Map("Merry go Round"),
        new Aoe2Map("Mesas"),
        new Aoe2Map("Metropolis"),
        new Aoe2Map("Michi"),
        new Aoe2Map("Microphone"),
        new Aoe2Map("Migration"),
        new Aoe2Map("Mirage"),
        new Aoe2Map("Mired"),
        new Aoe2Map("Mongolia"),
        new Aoe2Map("Monocle"),
        new Aoe2Map("Monsoon"),
        new Aoe2Map("Moorea"),
        new Aoe2Map("Morass"),
        new Aoe2Map("Mount Bromo"),
        new Aoe2Map("Mount Fuji"),
        new Aoe2Map("Mount Kailash"),
        new Aoe2Map("Mountain Coast"),
        new Aoe2Map("Mountain Pass"),
        new Aoe2Map("Mountain Range"),
        new Aoe2Map("Mountainside"),
        new Aoe2Map("Mudflow"),
        new Aoe2Map("Mystic Creek"),
        new Aoe2Map("Naivasha"),
        new Aoe2Map("New England"),
        new Aoe2Map("Nile Delta"),
        new Aoe2Map("Nomad"),
        new Aoe2Map("Nomad Ception"),
        new Aoe2Map("Nomad Islands"),
        new Aoe2Map("Northern Isles"),
        new Aoe2Map("Not Socotra"),
        new Aoe2Map("Oasis"),
        new Aoe2Map("Okavango"),
        new Aoe2Map("Ontario"),
        new Aoe2Map("Ounianga"),
        new Aoe2Map("Outback"),
        new Aoe2Map("Outcrop"),
        new Aoe2Map("Pacific Beach"),
        new Aoe2Map("Pacific Islands"),
        new Aoe2Map("Paddies"),
        new Aoe2Map("Pag"),
        new Aoe2Map("Painted Desert"),
        new Aoe2Map("Palisade Arena"),
        new Aoe2Map("Palm Valley"),
        new Aoe2Map("Pamir Mountains"),
        new Aoe2Map("Pandora"),
        new Aoe2Map("Panem"),
        new Aoe2Map("Pantanal"),
        new Aoe2Map("Patagonia"),
        new Aoe2Map("Peace-O-Pizza"),
        new Aoe2Map("Peatlands"),
        new Aoe2Map("Pilgrims"),
        new Aoe2Map("Pizza"),
        new Aoe2Map("Plains"),
        new Aoe2Map("Plateau"),
        new Aoe2Map("Pond Arena"),
        new Aoe2Map("Prairie"),
        new Aoe2Map("Quarry"),
        new Aoe2Map("Quebrada de Cafayate"),
        new Aoe2Map("Raft"),
        new Aoe2Map("Random River"),
        new Aoe2Map("Ravines"),
        new Aoe2Map("Red Sand"),
        new Aoe2Map("Rehydration"),
        new Aoe2Map("Reservoir"),
        new Aoe2Map("Rift Island"),
        new Aoe2Map("Ring Fortress"),
        new Aoe2Map("Ring of Reeds"),
        new Aoe2Map("River Belt"),
        new Aoe2Map("Rivers"),
        new Aoe2Map("Rivulet"),
        new Aoe2Map("Rockslide"),
        new Aoe2Map("Rocky Forest"),
        new Aoe2Map("Roe Rage"),
        new Aoe2Map("Rooster"),
        new Aoe2Map("Ruins"),
        new Aoe2Map("Runestones"),
        new Aoe2Map("Sacred Glades"),
        new Aoe2Map("Sacred Springs"),
        new Aoe2Map("Sahara"),
        new Aoe2Map("Salt Marsh"),
        new Aoe2Map("Sand Forest"),
        new Aoe2Map("Sand Maze"),
        new Aoe2Map("Sand Pit"),
        new Aoe2Map("Sand Trap"),
        new Aoe2Map("Savanna"),
        new Aoe2Map("Savannah Valley"),
        new Aoe2Map("Savonia"),
        new Aoe2Map("Scandinavia"),
        new Aoe2Map("Sea"),
        new Aoe2Map("Seafood Pie"),
        new Aoe2Map("Sealed Islands"),
        new Aoe2Map("Seize the Hump"),
        new Aoe2Map("Seize the Mountain"),
        new Aoe2Map("Serengeti"),
        new Aoe2Map("Shady Fish Market"),
        new Aoe2Map("Shallow Web"),
        new Aoe2Map("Sheepfold"),
        new Aoe2Map("Shelter"),
        new Aoe2Map("Shipwreck"),
        new Aoe2Map("Shoals"),
        new Aoe2Map("Shorelands"),
        new Aoe2Map("Silkroad"),
        new Aoe2Map("Sinkhole"),
        new Aoe2Map("Slippery Springs"),
        new Aoe2Map("Slopes"),
        new Aoe2Map("Snakepit"),
        new Aoe2Map("Socotra"),
        new Aoe2Map("Sonora"),
        new Aoe2Map("Soul"),
        new Aoe2Map("Spiny Forests"),
        new Aoe2Map("Spiral"),
        new Aoe2Map("Starfighter"),
        new Aoe2Map("Steppe"),
        new Aoe2Map("Sumatra"),
        new Aoe2Map("Sunburn"),
        new Aoe2Map("Swamp Fever"),
        new Aoe2Map("Taklamakan"),
        new Aoe2Map("Te Fiti"),
        new Aoe2Map("Te Waipounamu"),
        new Aoe2Map("Team Acropolis"),
        new Aoe2Map("Team Causeways"),
        new Aoe2Map("Team Islands"),
        new Aoe2Map("Team Migration"),
        new Aoe2Map("Tenochtitlan"),
        new Aoe2Map("Tentacle Isle"),
        new Aoe2Map("Terrace"),
        new Aoe2Map("Territorial"),
        new Aoe2Map("Texcoco"),
        new Aoe2Map("Thar Desert"),
        new Aoe2Map("The Bull"),
        new Aoe2Map("The Eye"),
        new Aoe2Map("The Hippo"),
        new Aoe2Map("Thicket"),
        new Aoe2Map("Tidal Plain"),
        new Aoe2Map("Tidepool"),
        new Aoe2Map("Tombolo"),
        new Aoe2Map("Trade Winds"),
        new Aoe2Map("Treasure Islands"),
        new Aoe2Map("Tremor"),
        new Aoe2Map("Tres Leches"),
        new Aoe2Map("Triple Arabia"),
        new Aoe2Map("Triple Pond Inspection"),
        new Aoe2Map("Tsunami"),
        new Aoe2Map("Twin Puddles"),
        new Aoe2Map("Two Rivers"),
        new Aoe2Map("Typhoon"),
        new Aoe2Map("Umm al Samim"),
        new Aoe2Map("Uplift Nomad"),
        new Aoe2Map("Ushuaia"),
        new Aoe2Map("Valley"),
        new Aoe2Map("Venicepest"),
        new Aoe2Map("Vital River"),
        new Aoe2Map("Volcanic Field"),
        new Aoe2Map("Volcanic Island"),
        new Aoe2Map("Volcanic Swamp"),
        new Aoe2Map("Wade"),
        new Aoe2Map("Water Nomad"),
        new Aoe2Map("Waterhole"),
        new Aoe2Map("Wet Web"),
        new Aoe2Map("Wilderness"),
        new Aoe2Map("Windmill"),
        new Aoe2Map("Winged"),
        new Aoe2Map("Wings"),
        new Aoe2Map("Winter's Clearing"),
        new Aoe2Map("Wolf Hill"),
        new Aoe2Map("Woodland"),
        new Aoe2Map("Woogy"),
        new Aoe2Map("Xerotes"),
        new Aoe2Map("Yam Suph"),
        new Aoe2Map("Yucatan"),
        new Aoe2Map("ZeSnake"),
        new Aoe2Map("ZeWall"),
        new Aoe2Map("Zealandia"),
        new Aoe2Map("Zero"),
    ];


    private constructor(name: string) {
        const id =  name.toLowerCase().replace(/[^a-z]/g, '-');
        super(id, name, Aoe2Map.defaultImageUrlsForMap(id), '');
    }

    public static defaultImageUrlsForMap(id: string): ImageUrls {
        const path = `/images/maps/${id}.png`;
        return {
            unit: path,
            emblem: path,
            animated_left: path,
            animated_right: path,
        }
    }

}

export default Aoe2Map;
