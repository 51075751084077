import * as React from "react";

class Practice extends React.Component<object, object> {
    public render() {
        return (
            <div className="container">
                <div className="content box">
                    <h3>Practice</h3>
                    <p>Sadly, this functionality is not implemented yet.</p>
                </div>
            </div>
        );
    }
}

export default Practice;