export enum Actions {
    SET_OWN_NAME = 'SET_OWN_NAME',
    SET_OWN_ROLE = 'SET_OWN_ROLE',
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_API_KEY = 'SET_API_KEY',
    SET_ADMIN_PRESETS_AND_DRAFTS = 'SET_ADMIN_PRESETS_AND_DRAFTS',
    SET_ICON_STYLE = 'SET_ICON_STYLE',
    SHOW_NAME_MODAL = 'SHOW_NAME_MODAL',
    SHOW_ROLE_MODAL = 'SHOW_ROLE_MODAL',
    SET_EDITOR_PRESET = 'SET_EDITOR_PRESET',
    SET_EDITOR_TURN = 'SET_EDITOR_TURN',
    DUPLICATE_EDITOR_TURN = 'DUPLICATE_EDITOR_TURN',
    SET_EDITOR_TURN_ORDER = 'SET_EDITOR_TURN_ORDER',
    SET_EDITOR_NAME = 'SET_EDITOR_NAME',
    SET_EDITOR_DRAFT_OPTIONS = 'SET_EDITOR_DRAFT_OPTIONS',
    SET_EDITOR_CATEGORY_LIMIT_PICK = 'SET_EDITOR_CATEGORY_LIMIT_PICK',
    SET_EDITOR_CATEGORY_LIMIT_BAN = 'SET_EDITOR_CATEGORY_LIMIT_BAN',
    SET_DRAFT_EVENTS = 'SET_DRAFT_EVENTS',
    SET_COLOR_SCHEME = 'SET_COLOR_SCHEME',
    SET_HIGHLIGHTED_ACTION = 'SET_HIGHLIGHTED_ACTION',
}

export enum ReplayActions {
    SET_COUNTDOWN_INTERVAL = 'SET_COUNTDOWN_INTERVAL',
    SET_EVENT_TIMEOUTS = 'SET_EVENT_TIMEOUTS',
    SET_STOP_COUNTDOWN = 'SET_STOP_COUNTDOWN',
}

export enum ServerActions {
    APPLY_CONFIG = 'APPLY_CONFIG',
    SET_PLAYER_CONNECTED = 'SET_PLAYER_CONNECTED',
    SET_PLAYER_NAME = 'SET_PLAYER_NAME',
    SET_READY = 'SET_READY',
    EXECUTE_ACTION = 'EXECUTE_ACTION',
    SET_EVENTS = 'SET_EVENTS',
    SET_COUNTDOWN_VALUE = 'SET_COUNTDOWN_VALUE',
    APPLY_REPLAY = 'APPLY_REPLAY',
    UPDATE_DRAFTS = 'UPDATE_DRAFTS',
    REMOVE_DRAFTS = 'REMOVE_DRAFTS',
}

export enum ClientActions {
    CONNECT_TO_SERVER = 'CONNECT_TO_SERVER',
    SEND_SET_ROLE = 'SEND_SET_ROLE',
    SEND_SET_NAME = 'SEND_SET_NAME',
    SEND_READY = 'SEND_READY',
    SEND_CLICK_PANEL = 'SEND_CLICK_PANEL',
    DISCONNECT_FROM_SERVER = 'DISCONNECT_FROM_SERVER',
    SPECTATE_DRAFTS = 'SPECTATE_DRAFTS',
    UNSPECTATE_DRAFTS = 'UNSPECTATE_DRAFTS',
    RESET_RECENT_DRAFTS_CURSOR = 'RESET_RECENT_DRAFTS_CURSOR',
}
